import config from '../../config';
import loadFirebase from '../firebase';

export default async () => {
    const firebase = await loadFirebase();
    await import('firebase/functions');

    try {
        firebase.initializeApp(config.firebase);
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }
    const functions = firebase.functions();
    if (process.env.GATSBY_EMULATOR === 'true') {
        functions.useEmulator('localhost', 5001);
    }
    return functions;
};
