import React, { useState } from 'react';
import { FormController, FieldType } from '../utils/FormController';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TextInput from '../components/fields/text-input';
import EmailInput from '../components/fields/email-input';
import Textarea from '../components/fields/textarea';

import loadFunctions from '../apis/functions';
let functions = null;

const Contact = () => {
    const [status, setStatus] = useState(null);
    const [callingEndpoint, setCallingEndpoint] = useState(false);
    const [formData, setFormData] = useState(
        new FormController({
            name: {
                type: FieldType.TEXT,
                validationRules: {
                    required: true,
                },
            },
            email: {
                type: FieldType.EMAIL,
                validationRules: {
                    required: true,
                },
            },
            message: {
                type: FieldType.TEXT,
                validationRules: {
                    required: true,
                },
            },
        })
    );

    const handleSubmit = async event => {
        event.preventDefault();

        setCallingEndpoint(true);
        const contactForm = formData.validate();

        if (contactForm.isValid) {
            functions = await loadFunctions();

            const { name, email, message } = contactForm.getFormValues();

            try {
                const { data } = await functions.httpsCallable('sendContactUsEmail')({
                    name,
                    email,
                    message,
                });

                const status = data.Message === 'OK' ? 'complete' : 'error';

                setStatus(status);
                setCallingEndpoint(false);
            } catch (error) {
                console.error(new Error(error));
                setStatus('error');
                setCallingEndpoint(false);
            }
        } else {
            setFormData(contactForm);
            setCallingEndpoint(false);
        }
    };

    const { name, email, message } = formData.formObject;

    return (
        <Layout>
            <SEO
                title="Contact | J Clarke Fitness"
                description="Contact J Clarke Fitness. If you have any questions about training, nutrition or my programmes, get in touch today."
            />
            <div className="content">
                {!status && (
                    <div>
                        <h1>Contact me</h1>
                        <form onSubmit={handleSubmit} method="POST">
                            <TextInput
                                label="Name"
                                required={true}
                                value={name.value}
                                onChange={value => {
                                    setFormData(formData.handleChange('name', value));
                                }}
                                validationMessage={name.errorMessage}
                            />
                            <EmailInput
                                label="Email"
                                required={true}
                                value={email.value}
                                onChange={value => {
                                    setFormData(formData.handleChange('email', value));
                                }}
                                validationMessage={email.errorMessage}
                            />
                            <Textarea
                                label="What can I help you with?"
                                required={true}
                                value={message.value}
                                onChange={value => {
                                    setFormData(formData.handleChange('message', value));
                                }}
                                validationMessage={message.errorMessage}
                            />
                            <input type="submit" disabled={callingEndpoint} value="Send" />
                        </form>
                    </div>
                )}

                {status === 'complete' && (
                    <div>
                        <h1>Thanks for contacting</h1>
                        <p>Your message has been sent and we'll get back to you soon.</p>
                    </div>
                )}

                {status === 'error' && (
                    <div>
                        <h1>There seems to be a problem</h1>
                        <p>
                            Something seems to have gone wrong. Why not trying again or if the
                            problem persists, try contacting us through one of our social channels.
                        </p>
                    </div>
                )}
            </div>
            <style jsx>{`
                h1 {
                    margin-bottom: 0;
                }

                .content {
                    background-color: hsl(4, 16%, 96%);

                    & > div {
                        max-width: 692px;
                        width: 100%;
                        padding: 32px 24px 40px;
                        box-sizing: border-box;
                        margin: 0 auto;
                    }
                }

                form {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }

                @media (min-width: 600px) {
                    form {
                        & :global(label) {
                            &:nth-child(-n + 2) {
                                width: 45%;
                            }

                            &:nth-child(2) {
                                order: -1;
                            }
                        }
                    }
                }
            `}</style>
        </Layout>
    );
};

export default Contact;
