import React from 'react';

interface InputRefObject {
    value: string;
}

interface TextareaProps {
    label?: any;
    validationMessage?: string;
    value?: string | number;
    onChange?: Function;
    required?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

const Textarea = ({
    label,
    validationMessage,
    value,
    onChange,
    required,
    placeholder,
    readOnly,
    disabled,
}: TextareaProps) => (
    <label>
        {label}
        <textarea
            className={validationMessage ? 'invalid' : ''}
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            required={required}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
        />
        {validationMessage && <p className="error-message">{validationMessage}</p>}
    </label>
);

export default Textarea;
